import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { submitYourRequestForm } from '../../services/get_a_quote';
import InputMask from 'react-input-mask';
import { GetAppointDate } from '../../services/get_a_quote';
import Select from 'react-select';
import PageTitleComponent from '../../components/PageTitleComponent';
import Cookies from 'js-cookie';
const ActnowBlogForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const ftype = (searchParams.size > 0) ? searchParams.get('ftype') : 0;
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);

    const [appointDate, setAppointDate] = useState([]);
    const GetAppointDateList = () => {
        GetAppointDate().then(function (res) {
            const resp = res;
            if (resp.Acknowledge == 1) {
                let AppointDateData: any = [];
                AppointDateData[0] = {
                    value: '',
                    label: 'Select Preferred'
                };
                resp.Dates.map((val: any, i: any) => {
                    AppointDateData[i + 1] = {
                        value: val.Id,
                        label: val.Name
                    };
                });
                setAppointDate(AppointDateData);
            }
        }).catch(err => {
            toast.error('Internal Server Error on getSalesPersonDropDown!');
        });
    };

    useEffect(() => {
        GetAppointDateList();
        window.scrollTo(0, 0);
    }, []);

    const [isLoading, setLoading] = useState(false);
    const [preferredDateOptionPreD, setPreferredDateOptionPreD] = useState('');
    const [preferredTimeOptionPreD, setPreferredTimeOptionPreD] = useState('');
    const [initialValuesBefor, setInitialValuesBefor] = useState({ full_name: '', address: '', zip: '', email: '', phone_number: '', aptdate: '', apttime: '', howdidyouhear: '', terms_of_use: '' });
    const [preferredTimeOption, setPreferredTimeOption] = useState([{ value: '', label: 'Select Preferred Time' }, { value: '09:00', label: '09:00 AM' }, { value: '09:30', label: '09:30 AM' }, { value: '10:00', label: '10:30 AM' }, {
        value: '11:00', label: '11:00 AM'
    }, { value: '11:30', label: '11:30 AM' }, { value: '12:00', label: '12:00 PM' }, { value: '12:30', label: '12:30 PM' }, { value: '13:00', label: '01:00 PM' }, { value: '13:30', label: '01:30 PM' }, {
        value: '14:00', label: '02:00 PM'
    },
    {
        value: '14:30',
        label: '02:30 PM'
    },
    {
        value: '15:00',
        label: '03:00 PM'
    },
    {
        value: '15:30',
        label: '03:30 PM'
    },
    {
        value: '16:00',
        label: '04:00 PM'
    },
    {
        value: '16:30',
        label: '04:30 PM'
    },
    {
        value: '17:00',
        label: '05:00 PM'
    },
    {
        value: '17:30',
        label: '05:30 PM'
    },
    {
        value: '18:00',
        label: '06:00 PM'
    },
    {
        value: '18:30',
        label: '06:30 PM'
    },
    {
        value: '19:00',
        label: '07:00 PM'
    },
    {
        value: '19:30',
        label: '07:30 PM'
    },
    {
        value: '20:00',
        label: '08:00 PM'
    },
    {
        value: '20:30',
        label: '08:30 PM'
    },
    {
        value: '21:00',
        label: '09:00 PM'
    }
    ]);

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: initialValuesBefor,
        validationSchema: Yup.object().shape({
            full_name: Yup.string().required('Name is required'),
            address: ftype == 2 || ftype == 3 ? Yup.string().required('Address is required') : Yup.string(),
            zip: Yup.string().required('Zip code is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phone_number: Yup.string().matches(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/, 'Invalid phone number format').required('Phone number is required'),
            aptdate: ftype == 2 || ftype == 3 ? Yup.string().required('Appointment date is required') : Yup.string(),
            apttime: ftype == 2 || ftype == 3 ? Yup.string().required('Appointment time is required') : Yup.string(),
            howdidyouhear: Yup.string().required('How did you hear about us?')
        }),

        onSubmit: (values, { resetForm }) => {
            const selectedOptionElement = document.getElementById('mySelect');
            const selectedIndex = selectedOptionElement.selectedIndex;
            const selectedOption = selectedOptionElement.options[selectedIndex];
            const createNewOBJ = {
                ...values,
                subsource: selectedOption.getAttribute('data-subsource')
            };
            setLoading(true);
            const objValue = {
                "AppNamespace": null,
                "AppSid": "",
                "AppVersion": "",
                "CheckVersion": null,
                "IpAddress": null,
                "Latitude": null,
                "Longitude": null,
                "AccountNumber": null,
                "Address1": values.address,
                "Address2": null,
                "ApptDate": values.aptdate,
                "ApptTime": values.apttime,
                "AreaOfInterest": null,
                "Caller": "landingsite",
                "City": null,
                "Comment": null,
                "Email": values.email,
                "EntryUser": null,
                "FirstName": values.full_name,
                "Keyword": Cookies.get('kw') ? Cookies.get('kw') : null,
                "LastName": null,
                "LeadSource": values.howdidyouhear,
                "LedgerType": null,
                "Phone1": values.phone_number,
                "Phone2": null,
                "Pid": Cookies.get('pid') ? Cookies.get('pid') : null,
                "PromoCode": null,
                "ReferSource": null,
                "ResCom": "",
                "SalesRep": null,
                "SecurityIssue": null,
                "State": null,
                "SubSource": createNewOBJ.subsource,
                "WebTrack": null,
                "Xid": null,
                "Zip": values.zip,
                "AllowCallPermission": values.terms_of_use ? values.terms_of_use : false
            };
            submitYourRequestForm(objValue).then(function (res) {
                const resp = res.data;
                setLoading(false);
                if (resp.Acknowledge >= 0) {
                    toast.success('Thank you! Your request has been successfully sent.');
                } else {
                    toast.error(resp.ErrorDesc);
                }
            }).catch(err => {
                toast.error('Internal Server Error!' + err);
            });
            resetForm();
        }
    });
    return (
        <>
            <PageTitleComponent title={"Send Request - Slomins"} />
            <main className="contact-page">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <h1>Send Request</h1>
                                <p>ACT NOW & SAVE ON CENTRAL AIR CONDITIONING - lowest prices of the year!</p>
                                <a className="white_btn" href="tel:18667566467"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28"></path>
                                </svg> 1-800-ALARM-ME</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact-form-blk">
                    <div className='container'>
                        <div className="row justify-content-center contact-form-row">
                            <div className="col-lg-12">
                                <div className="form-heading">
                                    <h3 className="mb-3">For any issues or concerns, please complete the form below.</h3>
                                    <p>Your information will be sent to us and one of our Customer Care Representatives will contact you shortly.</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="first-name" className="form-label">Full Name</label>
                                            <Form.Control
                                                id="full_name"
                                                name="full_name"
                                                type="text"
                                                placeholder="Full Name *"
                                                onChange={handleChange}
                                                value={values.full_name}
                                                isValid={touched.full_name && !errors.full_name}
                                                isInvalid={touched.full_name && !!errors.full_name}
                                            />
                                            {touched.full_name && errors.full_name ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.full_name}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <Form.Control
                                                id="email"
                                                name="email"
                                                type="text"
                                                placeholder="Email *"
                                                onChange={handleChange}
                                                value={values.email}
                                                isValid={touched.email && !errors.email}
                                                isInvalid={touched.email && !!errors.email}
                                            />
                                            {touched.email && errors.email ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        {ftype !== '1' ?
                                            <div className="col-12 col-md-6">
                                                <label>Address</label>
                                                <Form.Control
                                                    id="address"
                                                    name="address"
                                                    type="text"
                                                    placeholder="Address *"
                                                    onChange={handleChange}
                                                    value={values.address}
                                                    isValid={touched.address && !errors.address}
                                                    isInvalid={touched.address && !!errors.address}
                                                />
                                                {touched.address && errors.address ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.address}
                                                    </Form.Control.Feedback>
                                                ) : ''}
                                            </div>
                                            : ""
                                        }

                                        <div className="col-md-6 col-12">
                                            <label htmlFor="phone-number" className="form-label">Phone Number</label>
                                            <Form.Group>
                                                <InputMask
                                                    mask="(999) 999-9999"
                                                    placeholder="Enter phone number"
                                                    id="phone_number"
                                                    name="phone_number"
                                                    onChange={handleChange}
                                                    value={values.phone_number}
                                                >
                                                    {inputProps => <Form.Control {...inputProps} isValid={touched.phone_number && !errors.phone_number} isInvalid={touched.phone_number && !!errors.phone_number} />}
                                                </InputMask>
                                                <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        {ftype !== '1' ?
                                            <div className="col-12 col-md-6">
                                                <label>Preferred Date</label>
                                                <Form.Group className={`col-md-12 ${errors.aptdate ? 'select-validation-error' : ''}`} controlId="aptdate">
                                                    <Select
                                                        onChange={
                                                            selectedOption => {
                                                                handleChange({ target: { name: 'aptdate', value: selectedOption.value } });
                                                                setPreferredDateOptionPreD(selectedOption.value);
                                                            }}
                                                        placeholder="Select preferred date *"
                                                        value={preferredDateOptionPreD ? appointDate[preferredDateOptionPreD] : ''}
                                                        name="aptdate"
                                                        options={appointDate}
                                                    />
                                                    {errors.aptdate ? (
                                                        <div className='validation-error'>
                                                            {errors.aptdate}
                                                        </div>
                                                    ) : ''}
                                                </Form.Group>
                                            </div>
                                            : ""
                                        }
                                        {ftype !== '1' ?
                                            <div className="col-12 col-md-6">
                                                <label>Preferred Time</label>
                                                <Form.Group className={`col-md-12 ${errors.apttime ? 'select-validation-error' : ''}`} controlId="apttime">
                                                    <Select
                                                        onChange={
                                                            selectedOption => {
                                                                handleChange({ target: { name: 'apttime', value: selectedOption.value } });
                                                                setPreferredTimeOptionPreD(selectedOption.value);
                                                            }}
                                                        placeholder="Select preferred time *"
                                                        value={preferredTimeOptionPreD ? preferredTimeOption[preferredTimeOptionPreD] : ''}
                                                        name="apttime"
                                                        options={preferredTimeOption}
                                                    />
                                                    {errors.apttime ? (
                                                        <div className='validation-error is-invalid'>
                                                            {errors.apttime}
                                                        </div>
                                                    ) : ''}
                                                </Form.Group>
                                            </div>
                                            : ""
                                        }
                                        <div className="col-12 col-md-6">
                                            <label>Zip</label>
                                            <Form.Control
                                                id="zip"
                                                name="zip"
                                                type="text"
                                                placeholder="ZIP *"
                                                onChange={handleChange}
                                                value={values.zip}
                                                isValid={touched.zip && !errors.zip}
                                                isInvalid={touched.zip && !!errors.zip}
                                            />
                                            {touched.zip && errors.zip ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.zip}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label>How did you hear about us?</label>
                                            <select id="mySelect" className="form-control" name="howdidyouhear" onChange={handleChange} value={values.howdidyouhear}>
                                                <option value="">How did you hear about us? *</option>
                                                <option value="034" data-subsource="002">Internet</option>
                                                <option value="004" data-subsource="886">TV</option>
                                                <option value="001" data-subsource="886">Newspaper</option>
                                                <option value="003" data-subsource="886">Radio</option>
                                                <option value="008" data-subsource="886">Friend</option>
                                                <option value="034" data-subsource="013">Other</option>
                                            </select>
                                            {errors.howdidyouhear ? (
                                                <div className='validation-error'>
                                                    {errors.howdidyouhear}
                                                </div>
                                            ) : ''}
                                        </div>
                                        <div className="col-12">
                                            <div className="row sb_btn_wrap">
                                                <div className="col-12">
                                                    <div className="privacy_note">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value={values.terms_of_use} name="terms_of_use" onChange={handleChange} id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">You expressly agree to receive informational, emergency, marketing, and promotional text messages by or on behalf of SLOMIN’S, including via automated technology, at the telephone number provided above. Consent not required to make a purchase and you may revoke this consent at any time by texting STOP. Msg & data rates apply. You also agree to our <a href="/privacy-policy">Privacy Policy</a> and <a href="/term-of-use">Terms of Use</a>.</label>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="red_btn" disabled={isLoading ? "disabled" : ""}> {isLoading ? "Submitting..." : "Submit Your Request"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <SubscribeCompoents />
            </main>
        </>
    );
};

export default ActnowBlogForm;
